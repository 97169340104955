@use 'sass:map';
@import './grid_mdu';

.grid {
  @include grid-base();

  @media screen and (min-width: $grid-xl-begin-margin-auto) {
    margin: 0 auto;
  }
  &-full-width {
    @include grid-base();

    width: 100%;
    margin: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: map-get($default-negative-margins-map, sm);
  margin-right: map-get($default-negative-margins-map, sm);
}

.col {
  @include columns-loop(sm, $grid-sm-columns, $grid-sm-gutter);
}
