/* Floating button */
.floating_button_container {
  background-color: #ffffff;
  position: fixed;
  width: 140px;
  bottom: 30px;
  left: calc(50% - 70px);
  cursor: pointer;

  > button {
    border-radius: 80px;
    box-shadow: 0 8px 12px 3px rgb(2 160 139 / 30%);
    letter-spacing: normal;
  }
}
