@import '~ht-styleguide/build/colors';
@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/spacing';
@import 'src/styles/grid_mdu';

.container {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: fixed;
  top: 0;
  background-color: $ht-white;
  width: 100%;
  @include spacing(padding-left, $grid-sm-sideMargin);
  @include spacing(padding-right, $grid-sm-sideMargin);
}

.height_normal {
  height: 182px;
  transition: all 0.1s ease;
}

.height_reduced {
  height: 145px;
  transition: all 0.1s ease;
}

.container_navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: grey;

  input {
    height: 32px;
    border-radius: 30px;
  }
}

.filter_search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: grey;

  > div {
    flex: 1;
  }

  > div:last-of-type {
    display: flex;
    align-items: center;

    border: 1px solid $ht-navy-300 !important;
    border-radius: 30px !important;

    i {
      font-size: 20px;
    }
  }

  input {
    height: 32px;
    border-radius: 30px;
  }
}

.name {
  font-size: 24px;
  transition: all 0.3s ease;
  color: $ht-navy-900;
}

.name-sm {
  font-size: 20px;
  transition: all 0.3s ease;
}

.filter_icon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;
  border: 1px solid $ht-navy-300;
  border-radius: 4px;
  margin-right: 10px;

  i {
    font-size: 22px;
  }
}

.filter_icon_active {
  background-color: rgba($ht-teal-700, 0.2);
  border: 1px solid $ht-teal-700;

  i {
    color: $ht-teal-700;
  }
}

/* HEADER LIST VIEW */
.list_icon_row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 56px;
  background-color: $ht-white;

  @include spacing(padding-left, $grid-sm-sideMargin);
}

.list_container_left {
  display: flex;
  align-items: center;
  justify-content: left;

  i {
    font-size: 24px !important;
  }
}

.list_container_center {
  display: flex;
  align-items: center;
  justify-content: center;

  > i {
    font-size: 16px !important;
  }
}

.icon_more {
  color: $ht-navy-700;
  font-size: 24px;
}

// Tabs
.headerTab {
  flex: 1;
  width: 100%;
  font-weight: $ht-font-weight-bold;
  color: $ht-navy-300;
  @include spacing(padding, 2 0 !important);
  &.active {
    border-bottom: 2px solid $ht-teal-500;
    color: $ht-navy-900;
  }
}
