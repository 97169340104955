@use 'sass:map';

/*
  This is a derivative work of the <Grid/> component we have in ht-styleguide. Per Design this app is supposed to be
  mobile first/only, and has no need for tablet or desktop specific grid styling
*/

$scrollbar-pixel-width: 15px;

/*  mobile grid */
$grid-sm-columns: 4;
$grid-sm-sideMargin: 20px;
$grid-sm-gutter: 20px;

$grid-xl-breakpoint: 1440px;
$grid-xl-sideMargin: auto;
$grid-xl-begin-margin-auto: $grid-xl-breakpoint;

/*  Maps */
$default-gutters-map: (
  'sm': $grid-sm-gutter,
);

$default-negative-margins-map: (
  'sm': -$grid-sm-gutter/2,
);

$default-grid-margins-map: (
  'sm': $grid-sm-sideMargin,
);

/*  Mixins */
@function col-width($i, $columns) {
  @return 100% / $columns * $i;
}

@mixin col-props($i, $columns, $gutter) {
  @if $i == 0 {
    display: none;
  } @else {
    box-sizing: border-box;
    width: col-width($i, $columns);
    padding-left: $gutter/2;
    padding-right: $gutter/2;
  }
}

/*  Styles for .col property are declared separately from .col-xx-xx classes */
@mixin columns-loop($classname, $columns, $gutter) {
  flex: 1;
  box-sizing: border-box;
  padding-left: $gutter/2;
  padding-right: $gutter/2;
  &-#{$classname}-hidden {
    display: none;
  }
  @for $i from 0 through $columns {
    &-#{$classname}-#{$i} {
      @include col-props($i, $columns, $gutter);
    }
    &-#{$classname}-offset-#{$i} {
      margin-left: col-width($i, $columns);
    }
  }
}

@mixin grid-base() {
  box-sizing: border-box;
  margin-left: $grid-sm-sideMargin;
  margin-right: $grid-sm-sideMargin;
  max-width: $grid-xl-breakpoint - ($grid-lg-sideMargin * 2);
}

@mixin default-gutters($name) {
  padding-left: map-get($default-gutters-map, $name) / 2;
  padding-right: map-get($default-gutters-map, $name) / 2;
}

@mixin default-row-negative-margins($name) {
  margin-left: map-get($default-negative-margins-map, $name);
  margin-right: map-get($default-negative-margins-map, $name);
}

/* Use this to declare custom gutters in your Sass file */
@mixin use-custom-gutters($gutters) {
  margin-left: (-$gutters/2);
  margin-right: (-$gutters/2);
  & > * {
    padding-left: $gutters/2;
    padding-right: $gutters/2;
  }
}

/*  Use this to declare default gutters in your Sass file */
@mixin use-default-gutters($name) {
  @include default-row-negative-margins($name);
  & > * {
    @include default-gutters($name);
  }
}

@mixin use-custom-margins($margins) {
  margin-left: $margins;
  margin-right: $margins;
}

@mixin use-default-margins($name) {
  margin-left: map-get($default-grid-margins-map, $name);
  margin-right: map-get($default-grid-margins-map, $name);
}

@mixin use-padding-side-margins($name) {
  padding-left: map-get($default-grid-margins-map, $name);
  padding-right: map-get($default-grid-margins-map, $name);
}
