@import '~ht-styleguide/build/colors';
@import '~ht-styleguide/build/variables';

.container {
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  padding-top: 60px;

  .callout {
    display: flex;
    background-color: $ht-navy-200;
    border-radius: 50%;
    height: 88px;
    width: 88px;
    align-items: center;
    justify-content: center;
  }

  .icon_medium {
    font-size: 40px;
  }

  .icon_large {
    font-size: 48px;
  }
}
