@import '~ht-styleguide/build/colors';
@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/spacing';
@import '/src/styles/helpers.scss';

.grid {
  min-height: 100vh;
  // background-color: $ht-navy-050;
}

/* HEADER */
.header_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  width: 100%;
  height: 56px;
}
.header_container_left {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;

  > i {
    font-size: 30px !important;
  }
}
.header_container_center {
  display: flex;
  align-items: center;
  justify-content: center;

  > i {
    font-size: 30px !important;
  }
}

/* Progress */
.progress {
  width: 50px;
}
.svg {
  display: block;
  width: 100%;
}

/* TEAM ADD */
.search_wrapper {
  display: flex;
  align-items: center;

  .input {
    @include spacing(flex-basis, 30);
  }
}

.search_result_container {
  width: 100%;
  height: 40vh;
  position: absolute;
  white-space: normal;
  background: $ht-white;

  .results_content {
    height: 100%;

    overflow: auto;

    .results_lineitem {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @extend .hoverStyles100;
    }
  }
}
/* END TEAM ADD */

/* Container around the results (services) */
.container_services {
  @include spacing(padding-top, 2.5);
  @include spacing(padding-bottom, 12);
}

/* Status Colors */
.status_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 8px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Callout Box */
.calloutBox {
  margin-bottom: 16px;
}

/* Toast with Link */
.inlineAlertAnchor {
  color: inherit;
  text-decoration: underline !important;
}
