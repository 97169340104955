@import '~ht-styleguide/build/colors';
@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/spacing.scss';

/* CARD */
.container {
  border: 1px solid $ht-navy-200;
  border-radius: 4px;
  background-color: $ht-white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include spacing(padding, 2.5);
}
.container_services {
  border-bottom: 1px solid $ht-navy-200;
  border-radius: 4px;
  padding: 16px 0px;
  background-color: $ht-white;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
.container_center {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}
.container_edge {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .image_large {
    height: 36px;
    width: 36px;
  }
}
.name {
  color: $ht-navy-800;
  font-size: 14px;
  font-weight: $ht-font-weight-medium;
  line-height: 20px;
  letter-spacing: -0.35px;
  text-align: left;
}
.info {
  color: $ht-navy-700;
  flex-grow: 0;
  font-size: 11px;
  line-height: 1.45;
  letter-spacing: -0.08px;
  text-align: left;

  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Icon specific */
.icon_empty {
  border: 1px solid $ht-navy-300;
  border-radius: 50px;
  height: 24px;
  width: 24px;
  content: ' ';
}
