@import '~ht-styleguide/build/grid.scss';
@import '~ht-styleguide/build/variables.scss';

.container {
  h3,
  .h3 {
    margin-top: 0px !important;
  }

  button {
    letter-spacing: normal;
  }

  button > i {
    color: $ht-navy-900 !important;
  }

  @include with-md-grid() {
    height: 100% !important;
    width: 100% !important;
  }
}

.container_prompt {
  @extend .container;
  background-color: $ht-navy-050 !important;
}

.modalCloseIcon {
  display: none;
}

.subheader_large {
  padding-top: 25px;
}

.payment_details_grid_row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
