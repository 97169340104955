@import '~ht-styleguide/build/variables';

// Button Styles
.button_container {
  border: none;
  padding: 0;
  width: 84px;
  height: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.button_group_trailing__actions {
  // Use first/last of type to style multiple buttons that are side-by-side
  button:first-of-type {
    border-top-left-radius: map-get($ht-panel-border-radius, large);
    border-bottom-left-radius: map-get($ht-panel-border-radius, large);
  }
  button:last-of-type {
    border-top-right-radius: map-get($ht-panel-border-radius, large);
    border-bottom-right-radius: map-get($ht-panel-border-radius, large);
  }
}

.button_container_success {
  background-color: $ht-success-green-500;

  i {
    font-size: 22px !important;
    color: $ht-white;
  }

  p[class~='label'] {
    color: $ht-white;
  }
}
.button_container_cancel {
  background-color: $ht-white;
  border: 1px solid $ht-alert-red-700;

  i {
    font-size: 22px !important;
    color: $ht-alert-red-700;
  }

  p[class~='label'] {
    color: $ht-alert-red-700;
  }
}

.button_container_alert {
  background-color: $ht-alert-red-700;

  i {
    font-size: 22px !important;
  }

  p[class~='label'] {
    color: $ht-white;
  }
}
.button_container_flagged {
  background-color: $ht-white;
  border: 1px solid $ht-alert-red-700;

  i {
    font-size: 24px !important;
  }

  p[class~='label'] {
    color: $ht-alert-red-700;
  }
}
.button_container_unflagged,
.button_container_uncancel {
  background-color: $ht-navy-200;
  border: 1px solid $ht-dark-blue-700;

  i {
    font-size: 24px !important;
    color: $ht-dark-blue-700;
  }

  p[class~='label'] {
    color: $ht-dark-blue-700;
  }
}

// Icon and Icon Container Styles
$icon-font-size: 16px;
$icon-container-spacing: 10px;

.icon_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon_label {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.1px;
}
.icon_container_alert {
  background-color: $ht-alert-red-700;
}
.icon_container_uncancel {
  background-color: $ht-navy-200;
}

.icon {
  font-size: $icon-font-size;
  font-weight: $ht-font-weight-medium;
}
.icon_success {
  color: $ht-success-green-500;
}
.icon_alert {
  color: $ht-white;
}
.icon_cancel {
  color: $ht-alert-red-700;
}
.icon_flagged {
  color: $ht-alert-red-700;
}
.icon_undo {
  color: $ht-dark-blue-700;
}
