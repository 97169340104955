@import '~ht-styleguide/build/spacing_classes';
@import '~ht-styleguide/build/variables';

.container {
  position: sticky;
  top: 0;
  background-color: $ht-white;
}

.icon {
  color: $ht-navy-700;
  font-size: 24px;
}

.jobWithMultipleItemsInRow {
  list-style: disc;
  margin-left: 20px;
}

.status_caption {
  font-size: 9px !important;
}
