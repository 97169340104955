@import '~ht-styleguide/build/colors';
@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/spacing';
@import '/src/styles/helpers.scss';

$page-bg-color: $ht-navy-100;

.page {
  background-color: $page-bg-color;
  min-height: 100vh;
}

.container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: $page-bg-color;
  position: relative;
}

.container_inner {
  padding: 20px;
  height: 100%;
}

/* Progress */
.progress {
  width: 50px;
}
.svg {
  display: block;
  width: 100%;
}

/* TEAM ADD */
.search_wrapper {
  display: flex;
  align-items: center;

  .input {
    @include spacing(flex-basis, 30);
  }
}

.search_result_container {
  width: 100%;
  height: 40vh;
  position: absolute;
  white-space: normal;
  background: $ht-white;

  .results_content {
    height: 100%;

    overflow: auto;

    .results_lineitem {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @extend .hoverStyles100;
    }
  }
}
/* END TEAM ADD */
