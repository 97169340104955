@import '~ht-styleguide/build/colors';
@import '~ht-styleguide/build/variables';

.container {
  display: flex;
  align-items: center;
  border: 1px solid $ht-navy-300 !important;
  border-radius: 30px !important;
  color: $ht-navy-700;
  i {
    font-size: 20px;
  }
  input {
    height: 32px;
    border-radius: 30px;
    &::placeholder {
      color: $ht-navy-300;
      font-size: 12px;
    }
  }
}

.input_override {
  flex: 1;
  border: none !important;
  flex: 1;
}
