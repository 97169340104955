@import '~ht-styleguide/build/variables';

.editMenu {
  position: absolute;
  top: 45px;
  right: 40px;
  text-align: left;
  z-index: 1;
  li {
    display: flex;
    align-items: center;
    white-space: nowrap;
    list-style: none;
    border-radius: map-get($ht-panel-border-radius, large);
    cursor: pointer;

    &:hover {
      background-color: $ht-blue-700;
      color: $ht-white;
    }
  }
}

.callout_group {
  border-top: 1px solid $ht-navy-200;
  border-radius: 0px;
}
