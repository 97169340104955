
@font-face {
    font-family: 'indivisible-variable';
    src: local('indivisible-variable'), url('/fonts/IndivisibleWebVariableRoman.woff2') format('woff2');
    font-weight: 300 700;
    font-display: swap;
    unicode-range: U+000-5FF;
}


