@import 'reset.scss';
/* from ht-styleguide */
// Design System Specific
@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/spacing_classes';
@import '~ht-styleguide/build/typography_classes';
@import '~react-toastify/dist/ReactToastify.css';
@import 'helpers.scss';
@import 'grid_mdu.scss';
@import 'grid_mdu_classes.scss';
@import 'statuses.scss';

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

html,
body {
  font-family: $ht-font-family;
  font-weight: $ht-font-weight-regular;
  font-style: normal;

  height: 100%;
  width: 100%;

  @include spacing(margin, 0);
  @include spacing(padding, 0);

  border: 0;
  vertical-align: baseline;

  margin: 0;
  padding: 0;

  input,
  button,
  textarea {
    font-family: $ht-font-family;
  }
}

#__next {
  min-height: 100%;
  height: 100%;
  background-color: $ht-white;
}

// override toastify
.Toastify__toast {
  border-radius: map-get($ht-panel-border-radius, small);
  font-family: $ht-font-family;
  font-size: 12px;
}

.Toastify__toast--error {
  background: $ht-alert-red-700;
  color: #ffffff;
}

.Toastify__toast-container {
  width: 100% !important;
}

.Toastify__toast-body {
  @extend .h5;
  font-size: 14px;
  color: inherit !important;
}

.page_loader {
  position: fixed !important;
  background-color: $ht-navy-000;
  opacity: 0.7;
  z-index: 9999999999999999999 !important;
}

// need to get at buttons w/o adding additional dom elemnents
// react-swipeable
.swipeable-list-item__trailing-actions .swipe-action:first-of-type button {
  border-top-left-radius: map-get($ht-panel-border-radius, large);
  border-bottom-left-radius: map-get($ht-panel-border-radius, large);
}
.swipeable-list-item__trailing-actions .swipe-action:last-of-type button {
  border-top-right-radius: map-get($ht-panel-border-radius, large);
  border-bottom-right-radius: map-get($ht-panel-border-radius, large);
}

.swipeable-list-item__leading-actions .swipe-action:first-of-type button {
  border-top-left-radius: map-get($ht-panel-border-radius, large);
  border-bottom-left-radius: map-get($ht-panel-border-radius, large);
}
.swipeable-list-item__leading-actions .swipe-action:last-of-type button {
  border-top-right-radius: map-get($ht-panel-border-radius, large);
  border-bottom-right-radius: map-get($ht-panel-border-radius, large);
}
